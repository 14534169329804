import "./Landing.scss";
import { useState, useEffect } from "react";
import Quotes from "../../assets/images/quotes.svg";
import DesktopArrow from "../../assets/images/desktop-arrow.svg";
import Star from "../../assets/images/star.svg";
import Accessories from "../../assets/images/accessories.png";
import Clothing from "../../assets/images/clothing.png";
import Sneakers from "../../assets/images/sneakers.png";
import { useHistory } from "react-router-dom";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import EmailInput from "../../components/EmailInput/EmailInput";
import StaticGenerationPages from "../../components/StaticGenerationPages/StaticGenerationPages";

const SLIDES = [
  {
    title: "Sneakers",
    url: Sneakers,
  },
  {
    title: "Clothing",
    url: Clothing,
  },
  {
    title: "Accessories",
    url: Accessories,
  },
];
const SUBSCRIBE_URL = process.env.REACT_APP_API_HOST + "/api/v1/subscribe";
const INTERVAL_DURATION = 1500;

function Landing() {
  const [currentSlide, setCurrentSlide] = useState(SLIDES[0]);
  const [userEmail, setUserEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const handleSubmitUserEmail = () => {
    setIsLoading(true)

    fetch(SUBSCRIBE_URL, {
      method: "POST",
      mode: "cors",
      redirect: "follow",
      body: JSON.stringify({ email: userEmail }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          setIsLoading(false)
          return response.json();
        }

        return Promise.reject(response);
      })
      .then((responseJson) => {
        const params = new URLSearchParams();
        params.set("email", responseJson.email)

        history.push("/thank-you?" + params.toString());
      })
      .catch(async (errorUncoded) => {
        setIsLoading(false)
        const error = await errorUncoded.json();

        setEmailError(error.message);

        console.error("Error:", error.message);
      });
  };

  useEffect(() => {
    let currentSlideIndex = 0;

    const interval = () => {
      currentSlideIndex =
        currentSlideIndex + 1 > SLIDES.length - 1 ? 0 : currentSlideIndex + 1;

      setCurrentSlide(SLIDES[currentSlideIndex]);
    };

    setInterval(interval, INTERVAL_DURATION);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="page landing">
      <Header />

      <main className="main">
        <div className="phone-container">
          <img src={currentSlide.url} alt="" className="phone-image" />
          <BackgroundRays />
        </div>
        <div className="heading-container">
          <div className="left-side">
            <h3 className="discount bold">
              UP TO
              <div className="star-containeer">
                <div className="percent">50%</div>
                <img src={Star} alt="" className="discount-star" />
              </div>
              OFF
            </h3>

            <h2 className="dynamic-heading">{currentSlide.title}</h2>
          </div>
          <div className="arrow-container">
            <img
              src={DesktopArrow}
              alt=""
              className="heading-arrow hide-mobile"
            />
            <MobileArrow />
          </div>
          <h2 className="static-heading">
            Straight to <br />
            your Inbox
          </h2>
        </div>

        <div className="absolute-container">
          <div className="backstock-info">
            <img src={Quotes} alt="" className="quotes" />
            <h4 className="bold">
              BackStock is the only “newsletter” you will ever need.
            </h4>
            <p>
              We secure the best deals and prices on brands like: Nike, Jordan, Gucci, Louis Vuitton, 
              Fear Of God and Off-White (to name a few) and email them to you once a month.
            </p>
          </div>

          <div className="cta-container">
            <EmailInput
              value={userEmail}
              onChange={setUserEmail}
              onFocus={() => {
                setEmailError("");
              }}
              setError={setEmailError}
              onSubmit={handleSubmitUserEmail}
              isLoading={isLoading}
              buttonText="Sign Up"
              error={emailError}
              placeholder="Your email address..."
            />

            <p className="cta-info">
              By signing up, you agree to our Privacy Policy. <br />
              Unsubscribe anytime at the bottom of our emails.
            </p>
          </div>
        </div>
      </main>

      <div className="preloadImages">
        {SLIDES.map((slide) => (
          <img key={slide.title} src={slide.url} alt="" />
        ))}
      </div>

      <Footer />

      <StaticGenerationPages />
    </div>
  );
}

export default Landing;

function MobileArrow() {
  return (
    <svg
      className="heading-arrow hide-desktop"
      width="348"
      height="150"
      viewBox="0 0 348 150"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="#4120EE" strokeWidth="4.5" fill="none" fillRule="evenodd">
        <path d="M320.823 2.647H345v42.794H2.506v74.102h52.936" />
        <path d="m28.548 90.714 28.842 28.83-28.842 28.828" />
      </g>
    </svg>
  );
}

function BackgroundRays() {
  return (
    <svg
      className="background-rays"
      width="1122"
      height="743"
      viewBox="0 0 1122 743"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient
          x1="29.264%"
          y1="43.315%"
          x2="23.97%"
          y2="62.168%"
          id="m4z816po0a"
        >
          <stop stopColor="#CAC3FC" stopOpacity="0" offset="0%" />
          <stop stopColor="#CAC3FC" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="29.264%"
          y1="30.099%"
          x2="23.97%"
          y2="86.225%"
          id="rvo86o8nic"
        >
          <stop stopColor="#4120EE" stopOpacity="0" offset="0%" />
          <stop stopColor="#4120EE" offset="100%" />
        </linearGradient>
        <filter
          x="0%"
          y="0%"
          width="100%"
          height="100%"
          filterUnits="objectBoundingBox"
          id="64oudoyh8b"
        >
          <feGaussianBlur in="SourceGraphic" />
        </filter>
        <filter
          x="0%"
          y="0%"
          width="100%"
          height="100%"
          filterUnits="objectBoundingBox"
          id="hsmnqosr6d"
        >
          <feGaussianBlur in="SourceGraphic" />
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          filter="url(#64oudoyh8b)"
          d="M340.775 663.798 0 0h1422L590.38 707.742l-1 4.264z"
          fill="url(#m4z816po0a)"
        />
        <path
          filter="url(#hsmnqosr6d)"
          d="M115.224 559.353 0 0l741.448 102.044L364.83 613.296l-3 4.265z"
          transform="translate(225.552 74.445)"
          fill="url(#rvo86o8nic)"
        />
      </g>
    </svg>
  );
}
