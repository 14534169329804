import "./PrivacyPolicy.scss";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

function PrivacyPolicy() {

  return (
    <div className="page privacy-policy">
      <Header />

      <main className="main">
        <h1 className="page-header bold">Privacy Policy</h1>

        <p>
            BackStock, LLC ("us" or "we") values and respects the privacy of the parties 
            visiting our website and who requests to receive our email newsletters (the "Newsletters"). 
            This Privacy Policy describes our policies and procedures on the collection, use and disclosure 
            of Your information when You use the Service and tells You about Your privacy rights and how 
            the law protects You. We use Your Personal data to provide and improve our Service. By using 
            the Service, You agree to the collection and use of information in accordance with this Privacy Policy.
        </p>

        <h2>Interpretation and Definitions</h2>

        <h3>Interpretation</h3>
        <p>
            The words of which the initial letter is capitalized have meanings defined under the
            following conditions. The following definitions shall have the same meaning regardless 
            of whether they appear in singular or in plural.
        </p>

        <h3>Definitions</h3>
        <p>For the purposes of this Privacy Policy:</p>
        <ul>
            <li>
                <strong>Account</strong> means a unique account created for You to 
                access our Service or parts of our Service.
            </li>
            <li>
                <strong>Business</strong>, for the purpose of the CCPA (California Consumer Privacy Act), 
                refers to the Company as the legal entity that collects Consumers' personal information and 
                determines the purposes and means of the processing of Consumers' personal information, or 
                on behalf of which such information is collected and that alone, or jointly with others, 
                determines the purposes and means of the processing of consumers' personal information, 
                that does business in the State of California.
            </li>
            <li>
                <strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) 
                refers to BackStock, LLC, 433 Broadway, New York NY 10013.For the purpose of the GDPR, 
                the Company is the Data Controller.
            </li>
            <li>
                <strong>Consumer</strong>, for the purpose of the CCPA (California Consumer Privacy Act), means a 
                natural person who is a California resident. A resident, as defined in the law, 
                includes (1) every individual who is in the USA for other than a temporary or 
                transitory purpose, and (2) every individual who is domiciled in the USA who is 
                outside the USA for a temporary or transitory purpose.
            </li>
            <li>
                <strong>Cookies</strong> are small files that are placed on Your computer, 
                mobile device or any other device by a website, containing the details of 
                Your browsing history on that website among its many uses.
            </li>
            <li>
                <strong>Country</strong> refers to: New York, United States
            </li>
            <li>
                <strong>Data Controller</strong>, for the purposes of the GDPR (General Data Protection Regulation), 
                refers to the Company as the legal person which alone or jointly with others 
                determines the purposes and means of the processing of Personal Data.
            </li>
            <li>
                <strong>Device</strong> means any device that can access the Service such as a computer, a cellphone or a digital tablet.
            </li>
            <li>
                <strong>Do Not Track</strong> (DNT) is a concept that has been promoted by US regulatory 
                authorities, in particular the U.S. Federal Trade Commission (FTC), for the
                Internet industry to develop and implement a mechanism for allowing internet users 
                to control the tracking of their online activities across websites.
            </li>
            <li>
                <strong>Personal Data</strong> is any information that relates to an identified or identifiable 
                individual. For the purposes for GDPR, Personal Data means any information 
                relating to You such as a name, an identification number, location data, online 
                identifier or to one or more factors specific to the physical, physiological, 
                genetic, mental, economic, cultural or social identity.For the purposes of the 
                CCPA, Personal Data means any information that identifies, relates to, describes 
                or is capable of being associated with, or could reasonably be linked, directly or 
                indirectly, with You.
            </li>
            <li>
                <strong>Sale</strong>, for the purpose of the CCPA (California Consumer Privacy Act), means selling, 
                renting, releasing, disclosing, disseminating, making available, transferring, or 
                otherwise communicating orally, in writing, or by electronic or other means, a 
                Consumer's personal information to another business or a third party for monetary 
                or other valuable consideration.
            </li>
            <li>
                <strong>Service</strong> refers to the Website and Newsletters.
            </li>
            <li>
                <strong>Service Provider</strong> means any natural or legal person who processes the data on 
                behalf of the Company. It refers to third-party companies or individuals 
                employed by the Company to facilitate the Service, to provide the Service 
                on behalf of the Company, to perform services related to the Service or to 
                assist the Company in analyzing how the Service is used. For the purpose of 
                the GDPR, Service Providers are considered Data Processors.
            </li>
            <li>
                <strong>Third-party Social Media Service</strong> refers to any website or any social 
                network website through which a User can log in or create an account 
                to use the Service.
            </li>
            <li>
                <strong>Usage Data</strong> refers to data collected automatically, either generated 
                by the use of the Service or from the Service infrastructure itself 
                (for example, the duration of a page visit).
            </li>
            <li>
                <strong>Website</strong> refers to BackStock, accessible from <strong>BackStock.co</strong>
            </li>
            <li>
                <strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity 
                on behalf of which such individual is accessing or using the Service, as applicable.Under GDPR 
                (General Data Protection Regulation), You can be referred to as the Data Subject or as the User 
                as you are the individual using the Service.
            </li>
        </ul>

        <h2>Collecting and Using Your Personal Data</h2>
        <h3>Types of Data Collected</h3>

        <h4>Personal Data</h4>
        <p>
            While using Our Service, We may ask You to provide Us with certain personally identifiable 
            information that can be used to contact or identify You. Personally identifiable information 
            may include, but is not limited to:
        </p>
        <ul>
            <li>Email address</li>
            <li>First name and last name</li>
            <li>Address, State, Province, ZIP/Postal code, City</li>
            <li>Usage Data</li>
        </ul>

        <h4>Usage Data</h4>
        <p>
            Usage Data is collected automatically when using the Service. Usage Data may include 
            information such as Your Device's Internet Protocol address (e.g. IP address), browser 
            type, browser version, the pages of our Service that You visit, the time and date of 
            Your visit, the time spent on those pages, unique device identifiers and other diagnostic 
            data. When You access the Service by or through a mobile device, We may collect certain 
            information automatically, including, but not limited to, the type of mobile device You use, 
            Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, 
            the type of mobile Internet browser You use, unique device identifiers and other diagnostic data. 
            We may also collect information that Your browser sends whenever You visit our Service or when You 
            access the Service by or through a mobile device.
        </p>

        <h4>Tracking Technologies and Cookies</h4>
        <p>We may use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service. The technologies We use may include:</p>
        <ul>
            <li><strong>Cookies or Browser Cookies.</strong> A cookie is a small file placed on Your Device. You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of our Service. Unless you have adjusted Your browser setting so that it will refuse Cookies, our Service may use Cookies.</li>
            <li><strong>Web Beacons.</strong> Certain sections of our Service and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of a certain section and verifying system and server integrity).</li>
        </ul>

        <p>Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on Your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close Your web browser. You can learn more about cookies here: <strong>All About Cookies by TermsFeed.</strong> We use both Session and Persistent Cookies for the purposes set out below:</p>
        <ul>
            <li><strong>Necessary / Essential Cookies</strong> Type: Session Cookies Administered by: Us Purpose: These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services.</li>
            <li><strong>Cookies Policy / Notice Acceptance Cookies</strong> Type: Persistent Cookies Administered by: Us Purpose: These Cookies identify if users have accepted the use of cookies on the Website.</li>
            <li><strong>Functionality Cookies</strong> Type: Persistent Cookies Administered by: Us Purpose: These Cookies allow us to remember choices You make when You use the Website, such as remembering your login details or language preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter your preferences every time You use the Website.</li>
        </ul>
        <p>For more information about the cookies we use and your choices regarding cookies, please visit: <strong>https://www.allaboutcookies.org/.</strong></p>

        <h2>Use of Your Personal Data</h2>
        <p>When you submit any Personal Data through our Website, you agree to the terms of this Privacy Policy and consent to the treatment of your Personal Data. The Company may use Personal Data for the following purposes:</p>
        <ul>
            <li><strong>To provide and maintain our Service</strong>, including to monitor the usage of our Service.</li>
            <li><strong>To manage Your Account:</strong> to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.</li>
            <li><strong>For the performance of a contract:</strong> the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.</li>
            <li><strong>To contact You:</strong> To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.</li>
            <li><strong>To provide You</strong> with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already inquired about unless You have opted not to receive such information.</li>
            <li><strong>To manage Your requests:</strong> To attend and manage Your requests to Us.</li>
            <li><strong>For business transfers:</strong> We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.</li>
            <li><strong>For other purposes:</strong> We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services, marketing and your experience.</li>
        </ul>
        <p>We may share Your personal information in the following situations:</p>
        <ul>
            <strong>With Service Providers:</strong> We may share Your personal information with Service Providers to monitor and analyze the use of our Service, to contact You.
            <strong>For business transfers:</strong> We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.
            <strong>With Affiliates:</strong> We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.
            <strong>With business partners:</strong> We may share Your information with Our business partners to offer You certain products, services or promotions.
            <strong>With other users:</strong> when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside. If You interact with other users or register through a Third-Party Social Media Service, Your contacts on the Third-Party Social Media Service may see Your name, profile, pictures and description of Your activity. Similarly, other users will be able to view descriptions of Your activity, communicate with You and view Your profile.
            <strong>With Your consent:</strong> We may disclose Your personal information for any other purpose with Your consent.
        </ul>

        <h2>Retention of Your Personal Data</h2>
        <p>The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies. The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.</p>

        <h2>Transfer of Your Personal Data</h2>
        <p>Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction. Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer. The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.</p>
      
        <h2>Disclosure of Your Personal Data</h2>
        <h4>Business Transactions</h4>
        <p>If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.</p>

        <h4>Law enforcement</h4>
        <p>Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).</p>
        
        <h4>Other legal requirements</h4>
        <p>The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:</p>
        <ul>
            <li>Comply with a legal obligation</li>
            <li>Protect and defend the rights or property of the Company</li>
            <li>Prevent or investigate possible wrongdoing in connection with the Service</li>
            <li>Protect the personal safety of Users of the Service or the public</li>
            <li>Protect against legal liability</li>
        </ul>

        <h2>Security of Your Personal Data</h2>
        <p>The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.</p>
      
        <h2>Detailed Information on the Processing of Your Personal Data</h2>
        <p>Service Providers have access to Your Personal Data only to perform their tasks on Our behalf and are obligated not to disclose or use it for any other purpose.</p>

        <h2>Unsubscribe from Our Newsletters</h2>
        <p>If you no longer wish to receive our Newsletters to your email, you may click the unsubscribe link located at the bottom of each email message received and/or contact us at backstock@backstock.co and we will immediately remove you from our email list.</p>

        <h3>Google Data</h3>
        <h3>GDPR Privacy</h3>
        <h2>Legal Basis for Processing Personal Data under GDPR</h2>
        <p>We may process Personal Data under the following conditions:</p>
        <ul>
            <li><strong>Consent:</strong> You have given Your consent for processing Personal Data for one or more specific purposes.</li>
            <li><strong>Performance of a contract:</strong> Provision of Personal Data is necessary for the performance of an agreement with You and/or for any pre-contractual obligations thereof.</li>
            <li><strong>Legal obligations:</strong> Processing Personal Data is necessary for compliance with a legal obligation to which the Company is subject.</li>
            <li><strong>Vital interests:</strong> Processing Personal Data is necessary in order to protect Your vital interests or of another natural person.</li>
            <li><strong>Public interests:</strong> Processing Personal Data is related to a task that is carried out in the public interest or in the exercise of official authority vested in the Company.</li>
            <li><strong>Legitimate interests:</strong> Processing Personal Data is necessary for the purposes of the legitimate interests pursued by the Company.</li>
        </ul>
        <p>In any case, the Company will gladly help to clarify the specific legal basis that applies to the processing, and in particular whether the provision of Personal Data is a statutory or contractual requirement, or a requirement necessary to enter into a contract.</p>

        <h3>Your Rights under the GDPR</h3>
        <p>The Company undertakes to respect the confidentiality of Your Personal Data and to guarantee You can exercise Your rights. You have the right under this Privacy Policy, and by law if You are within the EU, to:</p>
        <ul>
            <li>Request access to Your Personal Data.</li> The right to access, update or delete the information We have on You. Whenever made possible, you can access, update or request deletion of Your Personal Data directly within Your account settings section. If you are unable to perform these actions yourself, please contact Us to assist You. This also enables You to receive a copy of the Personal Data We hold about You.
            <li>Request correction of the Personal Data that We hold about You.</li> You have the right to to have any incomplete or inaccurate information We hold about You corrected.
            <li>Object to processing of Your Personal Data.</li> This right exists where We are relying on a legitimate interest as the legal basis for Our processing and there is something about Your particular situation, which makes You want to object to our processing of Your Personal Data on this ground. You also have the right to object where We are processing Your Personal Data for direct marketing purposes.
            <li>Request erasure of Your Personal Data.</li> You have the right to ask Us to delete or remove Personal Data when there is no good reason for Us to continue processing it.
            <li>Request the transfer of Your Personal Data.</li> We will provide to You, or to a third-party You have chosen, Your Personal Data in a structured, commonly used, machine-readable format. Please note that this right only applies to automated information which You initially provided consent for Us to use or where We used the information to perform a contract with You.
            <li>Withdraw Your consent.</li> You have the right to withdraw Your consent on using your Personal Data. If You withdraw Your consent, We may not be able to provide You with access to certain specific functionalities of the Service.
        </ul>

        <h3>Exercising of Your GDPR Data Protection Rights</h3>
        <p>You may exercise Your rights of access, rectification, cancellation and opposition by contacting Us. Please note that we may ask You to verify Your identity before responding to such requests. If You make a request, We will try our best to respond to You as soon as possible. You have the right to complain to a Data Protection Authority about Our collection and use of Your Personal Data. For more information, if You are in the European Economic Area (EEA), please contact Your local data protection authority in the EEA. To exercise these rights, please contact us. via email at backstock@BackStock.co</p>

        <h2>CCPA Privacy</h2>
        <p>The California Consumer Privacy Act (CCPA) gives California residents certain rights with regards to their personal information as collected by companies as highlighted below. This privacy notice section for California residents supplements the information contained in Our Privacy Policy and it applies solely to all visitors, users, and others who reside in the State of California.</p>

        <h3>Your Rights under the CCPA</h3>
        <p>The CCPA provides California residents with specific rights regarding their personal information. If You are a resident of California, You have the following rights:</p>
        <ul>
            <li><strong>The right to notice.</strong> You have the right to be notified which categories of Personal Data are being collected and the purposes for which the Personal Data is being used.</li>
            <li><strong>The right to request.</strong> Under CCPA, You have the right to request that We disclose information to You about Our collection, use, sale, disclosure for business purposes and share of personal information. Once We receive and confirm Your request, We will disclose to You:</li>
            <ul>
                <li>The categories of personal information We collected about You</li>
                <li>The categories of sources for the personal information We collected about You</li>
                <li>Our business or commercial purpose for collecting or selling that personal information</li>
                <li>The categories of third parties with whom We share that personal information</li>
                <li>The specific pieces of personal information We collected about You</li>
                <li>
                    If we sold Your personal information or disclosed Your personal information for a business purpose, We will disclose to You:
                    <ul>
                        <li>The categories of personal information categories sold</li>
                        <li>The categories of personal information categories disclosed</li>
                    </ul>
                </li>
            </ul>
            <li><strong>The right to say no to the sale of Personal Data (opt-out)</strong>. You have the right to direct Us to not sell Your personal information. This does not apply here because BackStock does not sell your personal information.</li>
            <li>
                <strong>The right to delete Personal Data.</strong> You have the right to request the deletion of Your Personal Data, subject to certain exceptions. Once We receive and confirm Your request, We will delete (and direct Our Service Providers to delete) Your personal information from our records, unless an exception applies. We may deny Your deletion request if retaining the information is necessary for Us or Our Service Providers to:
                <ul>
                    <li>Complete the transaction for which We collected the personal information, provide a good or service that You requested, take actions reasonably anticipated within the context of our ongoing business relationship with You, or otherwise perform our contract with You.</li>
                    <li>Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or prosecute those responsible for such activities.</li>
                    <li>Debug products to identify and repair errors that impair existing intended functionality.</li>
                    <li>Exercise free speech, ensure the right of another consumer to exercise their free speech rights, or exercise another right provided for by law.</li>
                    <li>Comply with the California Electronic Communications Privacy Act (Cal. Penal Code § 1546 et. seq.).</li>
                    <li>Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when the information's deletion may likely render impossible or seriously impair the research's achievement, if You previously provided informed consent.</li>
                    <li>Enable solely internal uses that are reasonably aligned with consumer expectations based on Your relationship with Us.</li>
                    <li>Comply with a legal obligation.</li>
                    <li>Make other internal and lawful uses of that information that are compatible with the context in which You provided it.</li>
                </ul>
            </li>
            <li>
                <strong>The right not to be discriminated against.</strong> You have the right not to be discriminated against for exercising any of Your consumer's rights, including by:
                <ul>
                    <li>Denying goods or services to You</li>
                    <li>Charging different prices or rates for goods or services, including the use of discounts or other benefits or imposing penalties</li>
                    <li>Providing a different level or quality of goods or services to You</li>
                    <li>Suggesting that You will receive a different price or rate for goods or services or a different level or quality of goods or services</li>
                </ul>
            </li>
        </ul>

        <h3>Exercising Your CCPA Data Protection Rights</h3>
        <p>In order to exercise any of Your rights under the CCPA, and if You are a California resident, You can contact Us:</p>
        <ul>
            <li>By email: backstock@backstock.co</li>
        </ul>
        <p>Only You, or a person registered with the California Secretary of State that You authorize to act on Your behalf, may make a verifiable request related to Your personal information. Your request to Us must:</p>
        <ul>
            <li>Provide sufficient information that allows Us to reasonably verify You are the person about whom We collected personal information or an authorized representative</li>
            <li>Describe Your request with sufficient detail that allows Us to properly understand, evaluate, and respond to it</li>
        </ul>
        <p>We cannot respond to Your request or provide You with the required information if We cannot:</p>
        <ul>
            <li>Verify Your identity or authority to make the request</li>
            <li>And confirm that the personal information relates to You</li>
        </ul>
        <p>We will disclose and deliver the required information free of charge within 45 days of receiving Your verifiable request. The time period to provide the required information may be extended once by an additional 45 days when reasonable necessary and with prior notice. Any disclosures We provide will only cover the 12-month period preceding the verifiable request's receipt. For data portability requests, We will select a format to provide Your personal information that is readily useable and should allow You to transmit the information from one entity to another entity without hindrance.</p>
      
        <h3>Do Not Sell My Personal Information</h3>
        <p>You have the right to opt-out of the sale of Your personal information. Once We receive and confirm a verifiable consumer request from You, we will stop selling Your personal information. To exercise Your right to opt-out, please contact Us.</p>
      
        <h2>"Do Not Track" Policy as Required by California Online Privacy Protection Act (CalOPPA)</h2>
        <p>Our Service does not respond to Do Not Track signals. However, some third party websites do keep track of Your browsing activities. If You are visiting such websites, You can set Your preferences in Your web browser to inform websites that You do not want to be tracked. You can enable or disable DNT by visiting the preferences or settings page of Your web browser.</p>
      
        <h2>Your California Privacy Rights (California's Shine the Light law)</h2>
        <p>Under California Civil Code Section 1798 (California's Shine the Light law), California residents with an established business relationship with us can request information once a year about sharing their Personal Data with third parties for the third parties' direct marketing purposes. If you'd like to request more information under the California Shine the Light law, and if You are a California resident, You can contact Us using the contact information provided below.</p>
      
        <h2>California Privacy Rights for Minor Users (California Business and Professions Code Section 22581)</h2>
        <p>California Business and Professions Code section 22581 allow California residents under the age of 18 who are registered users of online sites, services or applications to request and obtain removal of content or information they have publicly posted. To request removal of such data, and if You are a California resident, You can contact Us using the contact information provided below, and include the email address associated with Your account. Be aware that Your request does not guarantee complete or comprehensive removal of content or information posted online and that the law may not permit or require removal in certain circumstances.</p>

        <h2>Links to Other Websites</h2>
        <p>Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit. We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>

        <h2>Children's Information</h2>
        <p>Our Website is not intended to be used by children under the age of 13 and we do not knowingly collect Personl Data from children under this age. We do not use any tool to determine the age of a visitor to the Website. All of the information We receive is treated as if it was submitted by an adult. If You have reason to believe that a child under the age of 13 has provided Us with personal information, please contact Us with sufficient detail to enable Us to delete that information. If it comes to our attention that a child has submitted information to us, we will delete that information as soon as possible.</p>

        <h2>Changes to this Privacy Policy</h2>
        <p>We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page. We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>

        <h2>Contact Us</h2>
        <p>If you have any questions about this Privacy Policy, You can contact us: By email: backstock@backstock.co</p>
      
      </main>

      <Footer />
    </div>
  );
}

export default PrivacyPolicy;
