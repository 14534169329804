import "./SocialLinks.scss";
import Facebook from "../../assets/images/facebook.svg";
import Instagram from "../../assets/images/instagram.svg";
import Twitter from "../../assets/images/twitter.svg";
import Mail from "../../assets/images/mail.svg";

function SocialLinks() {
    return (
      <div className="links-wrapper">
        <a href="https://twitter.com/backstockco" target="_blank" className="social-link">
          <img src={Twitter} alt="" />
        </a>
        <a href="https://www.instagram.com/backstock.co/" target="_blank" className="social-link">
          <img src={Instagram} alt="" />
        </a>
        <a href="mailto:backstock@backstock.co" className="social-link">
          <img src={Mail} alt="" />
        </a>
      </div>
    );
  }
  

export default SocialLinks;
