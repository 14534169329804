import "./Header.scss";
import BackStock from "../../assets/images/backstock.svg";
import SocialLinks from "../SocialLinks/SocialLinks";

function Header() {
  return (
    <header className="header">
      <a href="/">
        <img src={BackStock} alt="" />
      </a>

      <div className="hide-mobile">
        <SocialLinks />
      </div>
    </header>
  );
}

export default Header;
