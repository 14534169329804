import "./Footer.scss";
import SocialLinks from "../SocialLinks/SocialLinks";

function Footer() {
  return (
    <footer className="footer">
      <p className="copyright">
        © Copyright 2024 BackStock. All rights reserved.
      </p>
      <div className="flex-row">
        {/* <a href="#" className="footer-link">
          Terms of Services
        </a> */}
        <a href="/privacy-policy" className="footer-link">
          Privacy Policy
        </a>
      </div>
      <div className="hide-desktop">
        <SocialLinks />
      </div>
    </footer>
  );
}

export default Footer;
