import { Link } from "react-router-dom";
import "./StaticGenerationPages.scss"

function StaticGenerationPages() {
  return (
    <div className="hidden-links">
      <Link to="/thank-you">Thank you</Link>
    </div>
  );
}

export default StaticGenerationPages;
