import "./EmailInput.scss";
import { useState, useEffect, useRef } from "react";
import CTAArrow from "../../assets/images/cta-arrow.svg";

const EMAIL_REGEXP = new RegExp(
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
);

function EmailInput({
  error = "",
  value,
  buttonText,
  placeholder,
  onFocus,
  onChange,
  onSubmit,
  isLoading,
  setError,
}) {
  const [isSuccess, setIsSuccess] = useState(false);

  const prevIsLoading = useRef();

  useEffect(() => {
    if (error === "" && prevIsLoading.current && !isLoading) {
      setIsSuccess(true);

      setTimeout(() => {
        setIsSuccess(false);
      }, 1200);
    }
  }, [error, isLoading]);

  useEffect(() => {
    prevIsLoading.current = isLoading;
  }, [isLoading]);

  const validateEmail = () => {
    if (EMAIL_REGEXP.test(value)) {
      setError("");
      return true;
    }

    setError("please ented valid email.");
    return "please ented valid email.";
  };

  const handleSubmit = () => {
    if (!validateEmail()) {
      return;
    }

    onSubmit();
  };

  const handleEnterPress = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  const handleChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <div
      className={
        error.length === 0
          ? "cta-email-container"
          : "cta-email-container invalid"
      }
    >
      <MailSvg />
      <input
        value={value}
        onChange={handleChange}
        onBlur={validateEmail}
        onFocus={onFocus}
        type="email"
        className="cta-input"
        placeholder={placeholder}
        onKeyUp={handleEnterPress}
        disabled={isLoading}
      />
      <button
        className={`cta-submit ${isSuccess ? "success" : ""}`}
        onClick={handleSubmit}
        disabled={isLoading}
      >
        <img src={CTAArrow} alt="" className="cta-arrow hide-mobile" />
        <div className="animation-container">
          <span>
            {isLoading ? "Loading..." : isSuccess ? "Success" : buttonText}
          </span>
        </div>
      </button>
      <div className="email-error">{error}</div>
    </div>
  );
}

export default EmailInput;

function MailSvg() {
  return (
    <svg
      width="28"
      height="22"
      viewBox="0 0 28 22"
      xmlns="http://www.w3.org/2000/svg"
      className="mail-svg"
    >
      <path
        d="M25.28 0H3.606C2.147 0 .943 1.287.943 2.766v16.468C.943 20.714 2.147 22 3.605 22H25.28c1.458 0 2.662-1.287 2.662-2.766V2.766C27.943 1.286 26.739 0 25.28 0zm-.57 5.854L15.14 11.9c-.444.321-.95.321-1.394 0l-9.57-6.047c-.318-.193-.571-.58-.571-.965 0-.965 1.014-1.48 1.774-1.03l9.064 5.79 9.063-5.79c.76-.45 1.775.065 1.775 1.03 0 .386-.254.772-.57.965z"
        fill="#08061C"
        fillRule="evenodd"
      />
    </svg>
  );
}
