import "./ThankYou.scss";
import { useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import EmailInput from "../../components/EmailInput/EmailInput";
import useSearchParams from "../../hooks/useSearchParams";
import ASSC from "../../assets/images/ASSC-Square.png";
import Designer from "../../assets/images/designer-hoodie-square.png";
import Accessories from "../../assets/images/supreme-accessories.png";
import Supreme from "../../assets/images/supreme-square.png";

const INVITE_FRIEND_URL = process.env.REACT_APP_API_HOST + "/api/v1/invite";
const STATISTICS_URL = process.env.REACT_APP_API_HOST + "/api/v1/statistics";

function ThanksYou() {
  const [referalEmail, setReferalEmail] = useState("");
  const [referalEmailError, setReferalEmailError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [statistics, setStatictics] = useState({
    invitesSend: 0,
    invitesAccepted: 0,
    points: 0,
  });
  let query = useSearchParams()

  useEffect(() => {
    if (!query.toString()) {
      return;
    }

    fetch(STATISTICS_URL + `?${query.toString()}`, {
      method: "GET",
      mode: "cors",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }

        return Promise.reject(response);
      })
      .then((responseJson) => {
        setStatictics({
          invitesSend: responseJson.statistics.invitesSend,
          invitesAccepted: responseJson.statistics.invitesAccepted,
          points: responseJson.statistics.points,
        });
        setReferalEmail("");
      });
  }, []);

  const handleSubmitUserEmail = () => {
    setIsLoading(true);

    fetch(INVITE_FRIEND_URL + `?${query.toString()}`, {
      method: "POST",
      mode: "cors",
      redirect: "follow",
      body: JSON.stringify({ email: referalEmail }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }

        return Promise.reject(response);
      })
      .then((responseJson) => {
        setIsLoading(false);
        setStatictics({
          invitesSend: responseJson.statistics.invitesSend,
          invitesAccepted: responseJson.statistics.invitesAccepted,
          points: responseJson.statistics.points,
        });
        setReferalEmail("");
      })
      .catch(async (errorUncoded) => {
        const error = await errorUncoded.json();

        setIsLoading(false);
        setReferalEmailError(error.message);

        console.error("Error:", error.message);
      });
  };

  const renderLatestOffers = () => (
    <div className="latest-offers">
          <h2 className="bold offers-header">Check out the freshest offers!</h2>

          <div className="offers-container">
            <div className="offer-item">
              <div className="image-wrapper">
                <img
                  src="http://cdn.mcauto-images-production.sendgrid.net/3ed64e070a5a2338/6c9ee72c-60bd-415f-a077-8f426945106e/270x270.png"
                  alt=""
                />
              </div>

              <h3 className="offer-item-header bold">Air Jordan 1 Mid Shoes</h3>
              <p className="price">
                <span className="old-price">$333</span>
                <span className="bold discount">$299</span>
              </p>
            </div>
            <div className="offer-item">
              <div className="image-wrapper">
                <img
                  src="http://cdn.mcauto-images-production.sendgrid.net/3ed64e070a5a2338/6c9ee72c-60bd-415f-a077-8f426945106e/270x270.png"
                  alt=""
                />
              </div>

              <h3 className="offer-item-header bold">Air Jordan 1 Mid Shoes</h3>
              <p className="price">
                <span className="old-price">$333</span>
                <span className="bold discount">$299</span>
              </p>
            </div>
            <div className="offer-item">
              <div className="image-wrapper">
                <img
                  src="http://cdn.mcauto-images-production.sendgrid.net/3ed64e070a5a2338/6c9ee72c-60bd-415f-a077-8f426945106e/270x270.png"
                  alt=""
                />
              </div>

              <h3 className="offer-item-header bold">Air Jordan 1 Mid Shoes</h3>
              <p className="price">
                <span className="old-price">$333</span>
                <span className="bold discount">$299</span>
              </p>
            </div>
            <div className="offer-item">
              <div className="image-wrapper">
                <img
                  src="http://cdn.mcauto-images-production.sendgrid.net/3ed64e070a5a2338/6c9ee72c-60bd-415f-a077-8f426945106e/270x270.png"
                  alt=""
                />
              </div>

              <h3 className="offer-item-header bold">Air Jordan 1 Mid Shoes</h3>
              <p className="price">
                <span className="old-price">$333</span>
                <span className="bold discount">$299</span>
              </p>
            </div>
          </div>
        </div>
  )

  return (
    <div className="page thank-you">
      <Header />

      <main className="main">
        <h1 className="page-header bold">🥳 Thanks for signing in! 🥳</h1>

        {/* renderLatestOffers() */}

        <div className="referal">
          <h2 className="referal-header bold">
            Get Backpoints by referring friends!
          </h2>
          <div className="save">
          <h2 className="save-header bold">
            Save your URL to check in on your points: <a>{document.URL}</a>
          </h2>
        </div>
          <p className="referal-stats">
            <span>{statistics.invitesSend} friend(s) invited - </span>
            <span>
              {statistics.invitesAccepted} friend(s) accepted invitation -
            </span>
            <span> {statistics.points} backpoints received</span>
          </p>

          <div className="referal-container">
            <EmailInput
              value={referalEmail}
              error={referalEmailError}
              setError={setReferalEmailError}
              isLoading={isLoading}
              onSubmit={handleSubmitUserEmail}
              onChange={setReferalEmail}
              buttonText="Send invite"
              placeholder="Your friend email..."
            />
          </div>
        </div>

        <div className="referal-awards">
          <div className="award-container">
            <div className="image-wrapper">
              <img
                src={ASSC}
                alt=""
              />
            </div>
            <h3 className="award-header bold">ASSC or Revenge Tee</h3>
            <p className="point-price">150 Backpoints</p>
          </div>
          <div className="award-container">
            <div className="image-wrapper">
              <img
                src={Supreme}
                alt=""
              />
            </div>
            <h3 className="award-header bold">Supreme Graphic Tee</h3>
            <p className="point-price">300 Backpoints</p>
          </div>
          <div className="award-container">
            <div className="image-wrapper">
              <img
                src={Accessories}
                alt=""
              />
            </div>
            <h3 className="award-header bold">Supreme Accessory</h3>
            <p className="point-price">450 Backpoints</p>
          </div>
          <div className="award-container">
            <div className="image-wrapper">
              <img
                src={Designer}
                alt=""
              />
            </div>
            <h3 className="award-header bold">Designer Hoodie</h3>
            <p className="point-price">900 Backpoints</p>
          </div>
        </div>

        <div className="redeem">
          <p className="redeem-text bold">Email: <a href="mailto:backstock@backstock.co">backstock@backstock.co</a> to redeem points!</p>
        </div>
      </main>

      <Footer />
    </div>
  );
}

export default ThanksYou;
